import { useNotification } from 'naive-ui'
import { useI18n } from 'vue-i18n'

type Props = {
    title ?: string,
    description?: string,
    content?: string
}
export default function () {
  const { t } = useI18n()

  const { success: nSuccess, error: nError } = useNotification()
  const commonOptions = {
    duration: 5000,
    closable: true
  }

  const mergeOptions = (Props:Props) => {
    return {
      title: t('notification.general.title'),
      content: t('notification.general.content'),
      ...Props,
      ...commonOptions
    }
  }

  const success = (props:Props) => {
    nSuccess({
      ...props,
      ...commonOptions
    })
  }

  const error = (props:Props) => {
    nError(mergeOptions(props))
  }

  return {
    success,
    error
  }
}
